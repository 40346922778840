import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Slide1 from "../images/carousel.jpg";
// import anivasary from "../images/ani.jpg";
// import gift from "../images/gift.png";
// import balloon from "../images/balloon.png";
// import giftbox from "../images/giftbox.png";

import SlideMobile1 from "../images/SliderMobile1.jpg";

import { TypeAnimation } from "react-type-animation";

const Carousel = () => {
  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 5000,
  };
  return (
    <>
      <Slider {...settings} className=" no-mb vh slider-container">
        <div>
          {/* <div className="text-container"> */}
          {/* <div className="left-sec"></div> */}
          {/* <div className="right-sec"> */}
          {/* <img className="gift" alt="" src={gift} />
              <img className="balloon" alt="" src={balloon} /> */}
          {/* <div className="carousel-content"> */}
          {/* <TypeAnimation
                  className="no-mb"
                  style={{ fontSize: "60px" }}
                  cursor={true}
                  sequence={[
                    "  Topnotch Professional Installation Guaranteed",
                    3000,
                    "  Topnotch Professional Installation Guaranteed",
                    3000,
                  ]}
                  wrapper="h2"
                  repeat={Infinity}
                /> */}
          {/* <h2>
                  Celebrate our 6th year anniversary with us & enjoy special
                  discounts on all our solar-inverter packages!
                </h2>
                <p>Don’t miss out!</p>
                <p></p> */}

          {/* </div> */}
          {/* </div> */}
          {/* </div> */}
          <div id="overlay-x"></div>
          <img src={Slide1} alt="alt" />
          <Link to="/packages">
            <button className="btn-blink btn-secondary btn-carousel">
              Enjoy Now
            </button>
          </Link>
        </div>
      </Slider>
      <Slider {...settings} className="no-des slider-container">
        <div>
          {/* <div className="text-container">
            <div className="left-sec"></div>
            <div className="right-sec">
              <div className="carousel-content">
                <TypeAnimation
                  style={{ fontSize: "20px" }}
                  cursor={true}
                  sequence={[
                    "  Topnotch Professional Installation Guaranteed",
                    3000,
                    "Topnotch Professional Installation Guaranteed",
                    3000,
                  ]}
                  wrapper="h2"
                  repeat={Infinity}
                />
                <Link to="/packages">
                  <button className="btn btn-carousel">SHOP NOW</button>
                </Link>
              </div>
            </div>
          </div> */}
          {/* <div id="overlay"></div> */}
          <img src={Slide1} alt="alt" />
        </div>
      </Slider>
      <br />
      <center className="no-des">
        <Link to="/packages">
          <button className="btn btn-mobile btn-carousel">ENJOY NOW</button>
        </Link>
      </center>
    </>
  );
};

export default Carousel;
