import React, { useEffect, useState } from "react";
import Hero from "../../components/Hero";
import Banner from "../../components/Banner";
import { Link } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";

const Lithium = () => {
  const [packages, setPackages] = useState([]);
  useEffect(() => {
    const getData = async (e) => {
      let topList = [];
      const q = query(
        collection(db, "Packages"),
        where("batteryType", "==", "Lithium")
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        topList.push({ id: doc.id, ...doc.data() });
      });
      setPackages(topList);
    };
    getData();
  }, []);

  // console.log(packages);
  return (
    <div>
      <br />
      <Hero hero="roomsHerol">
        <Banner title="Lithium Battery Promo Packages">
          <Link to="/packages" className="btn-primary">
            Return to packages
          </Link>
        </Banner>
      </Hero>
      <br />
      <br />
      <header class="section-header">
        <h3>Explore our Lithium Battery Packages</h3>
        <div class="divider"></div>
        <p>
         We are excited to celebrate our 6th year anniversary of providing exceptional service and premium quality solar-inverter solutions! To show our gratitude, we're offering special discounts on our Lithium packages for a limited time, starting April 1st, 2025. Don't miss this opportunity to save big!
        </p>
      </header>
      <section className="package-container">
        <div class="overview-content">
          <ul class="features-list">
            {packages.map((item, index) => (
              <li key={index}>
                <Link to={"/packages/" + item.id}>
                  <span>{item.packages}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <br />
      </section>
    </div>
  );
};

export default Lithium;
